import React, { useContext, useEffect, useState } from "react";
import instance from "../../../../../utils/axiosHelper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CommonTable from "../../../../../components/commonTable";
import RiskWrapper from "../../../../../components/overview/riskWrapper";
import { Alert, Button, Dialog, backdropClasses } from "@mui/material";
import { datePayload } from "../../../../../utils/moduleTabApi";
import { Box, makeStyles } from "@material-ui/core";
import TrendWithCategoryAxis from "../../../../../components/trendGraph/trendWithCategory";
import { format, parseISO } from "date-fns";
import CommonPopup from "../../../../../components/commonPopup";
import "../../health/Styles.css";
import InfoIcon from "@material-ui/icons/Info";
import HealthColorWrapper from "../../../../../components/overview/healthColorWrapper";
import { GlobalData } from "../../../../../context/globalData";

function Process({
  date,
  showTrend,
  setShowTrend,
  getProcessStatus,
  recommendation,
}) {
  const [processData, setProcessData] = useState([]);
  const [trenData, setTrendData] = useState([]);
  const { projectId } = useParams();
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);

  const [columns] = useState([
    { title: "Name", field: "Name" },
    {
      title: "Health Score (%)",
      field: "HealthScore",
      render: (row) => (row.HealthScore * 100)?.toFixed(),
    },
    {
      title: "Health Status",
      field: "HealthStatus",
      render: (row) => <HealthColorWrapper status={row.HealthStatus} />,
    },
    {
      title: (
        <>
          Avg Pull Request Age (Days)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Avg Pull Request Age (Days)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "avgPrAge",
      render: (row) =>
        `${Math.ceil(row.avgPrAge)} ${row.avgPrAge < 2 ? "day" : "days"}`,
    },
    {
      title: (
        <>
          Completion Rate (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Completion Rate (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "CompletionRate",
      render: (row) => (row.CompletionRate * 100)?.toFixed(),
    },
    {
      title: (
        <>
          Commit Without Ticket Reference (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Commit Without Ticket Reference (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "PercentCommitWithoutTicketRef",
      render: (row) => (row.PercentCommitWithoutTicketRef * 100)?.toFixed(),
      // render: (row) => (row.PercentCommitWithoutTicketRef * 100)?.toFixed(2),
    },
    {
      title: (
        <>
          Commit With Pull Request (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Commit With Pull Request (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "PercentCommitWithoutPr",
      render: (row) => (row.PercentCommitWithoutPr * 100)?.toFixed(),
    },
    {
      title: (
        <>
          Merged Pull Request (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Merged Pull Request (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "PercentMergedPr",
      render: (row) => (row.PercentMergedPr * 100)?.toFixed(),
    },
    {
      title: (
        <>
          Merged Reviewed Pull Request (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Merged Reviewed Pull Request (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "PercentMergedReviewdPr",
      render: (row) => (row.PercentMergedReviewdPr * 100)?.toFixed(),
    },
    // ==========================================================================

    // { title: "Commits", field: "Commits" },
    // {
    //   title: "Commit Without Ticket Reference (%)",
    //   field: "CommitWithoutTicketRef",
    // },
    // { title: "Merged Pull Request", field: "MergedPr" },
    // { title: "Merged Reviewed Pull Request ", field: "MergedReviewdPr" },
    // { title: "Pull Request Count (%)", field: "PrCount" },
    // { title: "Commit Without Pull Request", field: "CommitWithoutPr" },
    // {
    //   title: "Task Assigned",
    //   field: "TaskAsigned",
    //   render: (row) => row.TaskAsigned.toFixed(),
    // },
    // { title: "Scope Change (%)", field: "ScopeChange" },
    // {
    //   title: "Task Completed",
    //   field: "TaskCompleted",
    //   render: (row) => row.TaskCompleted.toFixed(),
    // },
  ]);

  useEffect(() => {
    const dateUrl = datePayload(date);
    instance
      .get(`/api/v2/ph/processhealth/${projectId}?${dateUrl}`)
      .then((res) => {
        const data = res.data;
        getProcessStatus("Process", date);
        setProcessData(data);
      })
      .catch((err) => {
        console.error(err);
      });

    instance
      .get(`/api/v2/ph/trend/process/project/${projectId}?${dateUrl}`)
      .then((res) => {
        const data = res.data.map((e) => {
          const originalDate = parseISO(e.Date);
          return {
            ...e,
            Date: format(originalDate, "MMM yy"),
          };
        });
        setTrendData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date]);

  const [visibleColumns, setVisibleColumns] = useState(columns.slice(0, 5));
  const [showViewMore, setShowViewMore] = useState(true);

  const handleViewMore = () => {
    const currentLength = visibleColumns.length;
    const nextVisibleColumns = columns.slice(currentLength, currentLength + 5);
    setVisibleColumns([...visibleColumns, ...nextVisibleColumns]);

    if (currentLength + 5 >= columns.length) {
      setShowViewMore(false);
    }
  };

  return (
    <div>
      <CommonPopup
        open={showTrend}
        setOpen={setShowTrend}
        width={900}
        title={"Trend"}
      >
        <Box padding={3} width={850}>
          <TrendWithCategoryAxis body={"helthTrend"} data={trenData} />
        </Box>
      </CommonPopup>
      {recommendation?.status === "GOOD" ? null : (
        <Alert sx={{ mb: 3 }} severity="info">
          <b>Insights : </b>
          Based on our analysis, you need to improve on{" "}
          <b>
            {recommendation?.recommendation?.slice(0, -1).replaceAll(",", ", ")}
          </b>{" "}
          so that you can make your Process healthy.
        </Alert>
      )}
      <div className="healthTable">
        <CommonTable columns={visibleColumns} data={processData} />
      </div>
      {showViewMore && (
        <div
          style={{
            color: "rgb(65, 132, 142)",
            textAlign: "end",
            cursor: "pointer",
          }}
          onClick={handleViewMore}
          className="pt-2"
        >
          ...View More Column
        </div>
      )}
    </div>
  );
}

export default Process;
