import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Card, Grid, TextField } from "@material-ui/core";
import SelectInvestmentProfile from "../../components/select/selectInvestmentProfile";
import IssueTypeGraph from "./graph/IssueTypeGraph";
import CommonTable from "../../components/commonTable";
import SelectField from "../../components/select/selectField";
import RangeSelector from "../../components/rangeSelector";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import StackedColumnChart from "../../components/stackedColumnChart/StackedColumnChart";
import NormalSelect from "../../components/select/normalSelect";
import { getSelectedDate } from "../../utils/moduleTabApi";
import MultilineChart from "../../components/trendGraph/multiLineChart";
import { useEffect } from "react";
import instance from "../../utils/axiosHelper";
import { Alert, Autocomplete, CardContent, Popover } from "@mui/material";
import { config } from "../../Config/permission";
import AllTeamTrendBar from "../../pages/Project/projectDashboard/trend/AllTeamTrend";
import dollerImg from "../../assets/doller.png";

const useStyles = makeStyles((theme) => ({
  dropdownContainer: {
    margin: "30px 0px 20px 20px",
  },
  pieContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

const InvestmentProfileTemplate = ({
  setIssueType,
  issueColumn,
  issueList,
  pieGraphData,
  issueData,
  issueType,
  date,
  setDate,
  StackedColumnData,
  StackedColumnFlag,
  setIteration,
  iteration,
  trendDate,
  setTrendDate,
  sprintCompareData,
  isSprint = false,
  project,
  selectedAssignee,
  setSelectedAssignee,
  // EipicList,
  // selectedEpic,
  // epicDropDownHandler,
  // isEpicUrl,
  graphType,
  setGraphType,
  columnTitle,
  TrendUnit,
  trendLabel,
  IsAllTeamTrend,
  isCumulative,
  showCost,
  issueCostHandler,
  handleIssueCostClose,
  setIssueCostAmt,
  issueCostAmt,
  ApplyIssueHandler,
  totalCostInDoller,
}) => {
  const [openRange, setOpenRange] = useState(false);
  const [openRangeTrend, setOpenRangeTrand] = useState(false);
  const [assigneeList, setAssigneeList] = useState([]);

  const classes = useStyles();

  const option = {
    sorting: false,
    search: false,
    toolbar: false,
    pageSize: 10,
  };

  const selectIssueTypeHandler = (event) => {
    setIssueType(event.target.value);
  };

  const getResourceList = () => {
    instance
      .get(`/api/v2/projects/resourceAllocation/${project}`)
      .then((res) => {
        if (res.data) {
          const data = Object.keys(res.data).map((e) => {
            return { Name: e, Id: e };
          });
          const result = data.filter((e) => e.Name !== "null");
          // setSelectedAssignee(result[0].Id);
          setAssigneeList(result);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (project) {
      getResourceList();
    }
  }, [project]);

  return (
    <>
      <Card className="customCard" style={{ marginTop: "30px", width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={isSprint ? 4 : 12}>
            {isSprint && (
              <div className="text-center">
                <b>Current Sprint </b>
              </div>
            )}
            <IssueTypeGraph
              data={pieGraphData}
              legendPosition={isSprint ? "bottom" : "right"}
              height={isSprint ? "500px" : "250px"}
            />
          </Grid>
          {isSprint && (
            <Grid item xs={8}>
              <div className="text-center">
                <b>Last 5 Sprint Trend</b>
              </div>
              {/* Old flow of line chart */}
              {/* <MultilineChart
                TrendUnit={TrendUnit}
                yAxesLabel={trendLabel}
                data={sprintCompareData}
                height={"450px"}
              /> */}
              <StackedColumnChart
                graphData={sprintCompareData}
                yAxesLabel={"Test"}
                issueType={issueType}
              />
            </Grid>
          )}
        </Grid>
      </Card>
      {StackedColumnFlag && (
        <Card
          className="customCard"
          style={{ marginTop: "30px", width: "100%" }}
        >
          <div
            style={{
              width: "900px",
              paddingLeft: 20,
              paddingTop: 20,
              display: "flex",
              gap: 10,
              // width: 500,
            }}
          >
            <Box
              sx={{
                border: "1px solid lightGray",
                borderRadius: "6px",
                width: "100%",
                padding: "14px 0px",
                marginBottom: "10px",
                textAlign: "center",
                bgcolor: "#5ebdcc",
              }}
              color={"#FFFF"}
            >
              Trend Allocation By
            </Box>
            <NormalSelect
              option={[
                { label: "Monthly", value: "Monthly" },
                { label: "Biweekly", value: "Biweekly" },
                // { label: "Quarterly", value: "Quarterly" },
                { label: "Weekly", value: "Weekly" },
                { label: "Daily", value: "Daily" },
              ]}
              value={iteration}
              handleChange={(value) => setIteration(value)}
            />
            <NormalSelect
              option={[
                { label: config.type, value: "storypoint  " },
                { label: "Workday", value: "workday" },
              ]}
              value={graphType}
              handleChange={(value) => setGraphType(value)}
            />
            {trendDate && (
              <div>
                {/* Date drop down */}
                <SelectField
                  input={
                    <Button
                      onClick={() => setOpenRangeTrand(true)}
                      style={{
                        border: "1px solid #b7b7b7",
                        width: "230px",
                        textTransform: "capitalize",
                        padding: "5px",
                        fontSize: "14px",
                        fontWeight: 400,
                        paddingLeft: "14px ",
                        paddingRight: "11px",
                        justifyContent: "space-between",
                        borderRadius: "25px",
                        height: "48px",
                      }}
                      endIcon={<DateRangeOutlinedIcon />}
                    >
                      {getSelectedDate(trendDate)}
                    </Button>
                  }
                  onClick={() => setOpenRangeTrand(true)}
                />
                <RangeSelector
                  isNextDate={false}
                  open={openRangeTrend}
                  setOpen={setOpenRangeTrand}
                  pickerHandler={(val) => setTrendDate(val)}
                  value={trendDate}
                />
              </div>
            )}
          </div>
          {StackedColumnData && (
            <StackedColumnChart
              graphData={StackedColumnData}
              // graphType={graphType}
              unit={TrendUnit}
              yAxesLabel={trendLabel}
            />
          )}
          {IsAllTeamTrend && (
            <AllTeamTrendBar
              data={IsAllTeamTrend}
              YAxisLabel={trendLabel}
              iteration={iteration}
              unit={TrendUnit}
            />
          )}
          {!iteration && (
            <p style={{ padding: 15 }}>
              Note : Please select Iteration to see trend{" "}
            </p>
          )}
        </Card>
      )}

      <Card className="customCard" style={{ marginTop: "30px" }}>
        <div className={classes.dropdownContainer}>
          <Box display={"flex"} gap="20px" alignItems={"center"}>
            <SelectInvestmentProfile
              handleChange={selectIssueTypeHandler}
              list={issueList}
              value={issueType}
              iteration={true}
              isAll={true}
            />
            {project && (
              <SelectInvestmentProfile
                handleChange={(e) => setSelectedAssignee(e.target.value)}
                list={assigneeList}
                value={selectedAssignee}
                iteration={false}
                isAll={true}
              />
            )}
            {date && (
              <div>
                {/* Date drop down */}
                <SelectField
                  input={
                    <Button
                      onClick={() => setOpenRange(true)}
                      style={{
                        border: "1px solid #b7b7b7",
                        width: "230px",
                        textTransform: "capitalize",
                        padding: "5px",
                        fontSize: "14px",
                        fontWeight: 400,
                        paddingLeft: "14px ",
                        paddingRight: "11px",
                        justifyContent: "space-between",
                        borderRadius: "25px",
                        height: "48px",
                      }}
                      endIcon={<DateRangeOutlinedIcon />}
                    >
                      {getSelectedDate(date)}
                    </Button>
                  }
                  onClick={() => setOpenRange(true)}
                />
                <RangeSelector
                  isNextDate={false}
                  open={openRange}
                  setOpen={setOpenRange}
                  pickerHandler={(val) => setDate(val)}
                  value={date}
                />
              </div>
            )}
            {isCumulative && (
              <div className="border p-2 rounded">
                Cumulative FTE: {isCumulative.toFixed(2)}
              </div>
            )}
            {isCumulative && (
              <div className="border p-1 rounded mx-4" onClick={showCost}>
                <img src={dollerImg} alt="dollerImg" width={"32px"} /> Cost $:{" "}
                {totalCostInDoller ? totalCostInDoller : 0}
              </div>
            )}
            <Popover
              open={Boolean(issueCostHandler)}
              anchorEl={issueCostHandler}
              onClose={handleIssueCostClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Card>
                <CardContent>
                  <div>
                    <label>
                      Please put your fully loaded engineers cost per{" "}
                      {config.type === "Task" ? "Week" : "Month"}
                    </label>
                    <br />
                    <Box display={"flex"} alignItems={"center"} gridGap={2}>
                      $
                      <TextField
                        value={issueCostAmt}
                        onChange={(e) => {
                          setIssueCostAmt(e.target.value);
                        }}
                        style={{
                          width: "400px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                        id="outlined-basic"
                        variant="outlined"
                      />
                    </Box>
                    <br />
                    <Button
                      disabled={issueCostAmt ? false : true}
                      onClick={() => {
                        ApplyIssueHandler();
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      {" "}
                      Apply
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Popover>
          </Box>
        </div>
        <CommonTable
          title={columnTitle ? columnTitle : ""}
          columns={issueColumn}
          data={issueData}
          options={{ search: false }}
        />
      </Card>
    </>
  );
};

export default InvestmentProfileTemplate;
