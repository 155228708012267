import React, { useState, useContext, useEffect } from "react";
import { GlobalData } from "../../context/globalData";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { Alert, AlertTitle } from "@material-ui/lab";
import axios from "../../utils/axiosHelper";
import { useHistory } from "react-router-dom";
import arrow from "../../assets/arrowpath.png";
import userIcon from "../../assets/usericon.png";
import passwordIcon from "../../assets/passwordicon.png";
import emailIcon from "../../assets/emailicon.png";
import glogo from "../../assets/glogo.png";
import itegenic_logo4 from "../../assets/itegenic_logo4.png";
import logo from "../../assets/itechLogo.png";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { InputAdornment } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "5px",
  },
  inputWraper: {
    position: "relative",
    left: "75px",
    top: "44px",
  },
  input: {
    width: "300px",
    padding: "12px 15px 12px 30px",
    margin: "3px",
    fontSize: "16px",
  },
  btn: {
    width: "317px",
    borderRadius: "32px",
    color: "#fff",
    background: "#5EBDCC 0% 0% no-repeat padding-box",
    "&:hover": {
      background: "#5EBDCC 0% 0% no-repeat padding-box",
    },
    fontFamily: "Lexend Deca, SemiBold",
    fontSize: "18px",
  },
  btnWraper: {
    width: "200px",
    marginTop: "20px",
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  alert: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    margin: "10px",
  },
  btnnew: {
    display: "flex",
    width: "350px",
    position: "relative",
    top: "54px",
    justifyContent: "center",
    left: "94px",
  },
  notchedOutline: {
    borderWidth: "1px",
    borderRadius: "0px",
    margin: "10px",
    borderTop: "0px",
    borderRight: "0px",
    borderLeft: "0px",
  },
  signUpsec: {
    textAlign: "center",
    flex: "auto",
    position: "relative",
    top: "30px",
    left: "-30px",
  },
  hr: {
    width: "118px",
    position: "relative",
    top: "31px",
    right: "48px",
  },
  hrRight: {
    bottom: "25px",
    width: "118px",
    position: "relative",
    left: "130px",
  },
  buttonSignIN: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "#5EBDCC",
    marginLeft: "2px",
  },
  cardNewContainer: {
    width: "100vw",
    top: "0px",
    left: "0px",
    background: "#5EBDCC 0% 0% no-repeat padding-box",
    opacity: "1",
    display: "flex",
  },
  newContainerLeft: {
    width: "55%",
    height: "100vh",
    opacity: "1",
    position: "relative",
    textAlign: "center",
  },
  newContainerRight: {
    top: "0px",
    width: "45%",
    height: "100vh",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "20px 0px 0px 20px",
    opacity: "1",
    position: "relative",
    border: "1px solid #5EBDCC",
  },
  arrowClass: {
    top: "55px",
    left: "40px",
    width: "18px",
    height: "16px",
    opacity: "1",
    position: "relative",
  },
  signUpClass: {
    top: "42px",
    left: "100px",
    font: "normal normal 600 32px/22px Lexend Deca",
    letterSpacing: "0px",
    color: "#1E1E1E",
    opacity: "1",
    position: "relative",
    fontSize: "22px",
  },
  signUptagLine: {
    top: "46px",
    left: "100px",
    font: "normal normal normal 14px/22px Lexend Deca",
    letterSpacing: "0px",
    color: "#7E7E7E",
    opacity: "1",
    position: "relative",
  },
  usrImg: {
    position: "relative",
    top: "15px",
    left: "26px",
  },
  textWraper: {
    top: "35px",
    position: "relative",
    textAlign: "center",
    left: "168px",
  },
  gLogo: {
    textAlign: "center",
    position: "relative",
    top: "18px",
    left: "-40px",
  },
  glogoCls: {
    width: "127px",
  },
  leftText: {
    position: "relative",
    bottom: "5px",
    color: "#FFFFFF",
  },
  newMainLogo: {
    textAlign: "center",
    position: "relative",
    top: "20px",
    left: "12px",
  },
  errMsges: {
    textAlign: "center",
    color: "#7E7E7E",
    position: "relative",
    top: "300px",
    left: "15px",
  },
}));

const SignUp = () => {
  const { setIsSignUp, setIsVerification, isVerification } =
    useContext(GlobalData);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [pricePlanList, setPricePlanList] = useState([]);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [isConfirmVisiblePassword, setIsConfirmVisiblePassword] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  let planId = pricePlanList[0]?._id;

  const nameRegExp = /^[a-zA-Z ]{1,30}$/;

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .matches(
        nameRegExp,
        "Firstname must be alphabate and it does not allow special character and max 30 characters"
      )
      .required("Firstname is required"),
    lastName: yup
      .string()
      .matches(
        nameRegExp,
        "Lastname must be alphabate and it does not allow special character and max 30 characters"
      )
      .required("Lastname is required"),
    email: yup
      .string()
      .email("Enter a valid E-Mail")
      .required("E-Mail is required"),
    password: yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .required("Please retype your password.")
      .oneOf([yup.ref("password")], "Your passwords do not match."),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      pricingPlan: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { confirmPassword, ...newValues } = values;
      setLoading(true);
      axios
        .post("api/auth/register", newValues)
        .then((result) => {
          if (result.data.success) {
            setIsSignUp(true);
            // history.push("/login");
            setLoading(false);
            setIsVerification(true);
            formik.resetForm();
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data.error);
          setLoading(false);
          setError(true);
          setTimeout(function () {
            setError(false);
          }, 3000);
        });
    },
  });

  const pricingPlanHandler = () => {
    axios
      .get("api/pricingplans")
      .then((result) => {
        if (result.data.success) {
          setPricePlanList(result.data.data);
          formik.setFieldValue("pricingPlan", result.data.data[0]?._id);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    pricingPlanHandler();
  }, []);

  const signInHandler = () => {
    history.push("/login");
  };

  const visibilityHandler =()=>{
    setIsVisiblePassword(!isVisiblePassword);
  }
  const confirmVisibilityHandler =()=>{
    setIsConfirmVisiblePassword(!isConfirmVisiblePassword);
  }

  return (
    <>
      <div className={classes.cardNewContainer}>
        <div className={classes.newContainerLeft}>
          <img
            src={itegenic_logo4}
            alt="itechgenic"
            style={{
              width: "429px",
              height: "424px",
              marginTop: "4%",
              textAlign: "center",
              backgroundColor: "#5EBDCC",
            }}
          />

          <div className={classes.leftText}>
            <br />
            <div
              className="col-12 d-flex justify-content-center"
              style={{
                color: "black",
                fontSize: "18px",
                fontWeight: "800",
                letterspacing: "0px",
                opacity: "0.5",
              }}
            >
              Enhance your cycle time and improve code quality.
            </div>
          </div>
        </div>
        <div className={classes.newContainerRight}>
          {isVerification ? (
            <h2 className={classes.errMsges}>Please check the mail.</h2>
          ) : (
            <>
              {/* <div className={classes.newMainLogo}>
                <img
                  src={logo}
                  alt="itechgenic"
                  style={{ width: "35%" }}
                />
              </div> */}

              <div className={classes.arrowClass}>
                <span className={classes.buttonSignIN} onClick={signInHandler}>
                  <img src={arrow} />
                </span>
              </div>
              <div className={classes.signUpClass}>Sign Up</div>
              <div className={classes.signUptagLine}>
                Please fill the details asked
              </div>
              <form
                noValidate
                autoComplete="off"
                className={classes.root}
                onSubmit={formik.handleSubmit}
              >
                <div className={classes.formFields}>
                  <div className={classes.inputWraper}>
                    <img src={userIcon} className={classes.usrImg} />
                    <TextField
                      id="firstName"
                      name="firstName"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      placeholder="Firstname"
                    />
                  </div>

                  <div className={classes.inputWraper}>
                    <img src={userIcon} className={classes.usrImg} />
                    <TextField
                      id="lastName"
                      name="lastName"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      placeholder="Lastname"
                    />
                  </div>

                  <div className={classes.inputWraper}>
                    <img src={emailIcon} className={classes.usrImg} />
                    <TextField
                      id="email"
                      name="email"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      placeholder="E-Mail"
                    />
                    {error && (
                      <div className={classes.alert}>
                        <Alert severity="error">
                          <AlertTitle>Error</AlertTitle>
                          {errorMessage}
                        </Alert>
                      </div>
                    )}
                  </div>

                  <div className={classes.inputWraper}>
                    <img src={passwordIcon} className={classes.usrImg} />
                    <TextField
                      style={{ width: "350px" }}
                      id="password"
                      name="password"
                      type={!isVisiblePassword ? "password" : "text"}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            {!isVisiblePassword ? (
                              <VisibilityOffIcon
                                style={{ cursor: "pointer", color: "#7E7E7E" }}
                                onClick={() => {
                                  visibilityHandler();
                                }}
                              />
                            ) : (
                              <VisibilityIcon
                                style={{ cursor: "pointer", color: "#7E7E7E" }}
                                onClick={() => {
                                  visibilityHandler();
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      placeholder="Password"
                    />
                  </div>

                  <div className={classes.inputWraper}>
                    <img src={passwordIcon} className={classes.usrImg} />
                    <TextField
                     style={{ width: "350px" }}
                      id="confirmPassword"
                      name="confirmPassword"
                      type={!isConfirmVisiblePassword ? "password" : "text"}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            {!isConfirmVisiblePassword ? (
                              <VisibilityOffIcon
                                style={{ cursor: "pointer", color: "#7E7E7E" }}
                                onClick={() => {
                                  confirmVisibilityHandler();
                                }}
                              />
                            ) : (
                              <VisibilityIcon
                                style={{ cursor: "pointer", color: "#7E7E7E" }}
                                onClick={() => {
                                  confirmVisibilityHandler();
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      placeholder="Confirm password"
                    />
                  </div>

                  <div className={classes.btnnew}>
                    <div>
                      <Button
                        variant="contained"
                        className={classes.btn}
                        type="submit"
                        disabled={loading}
                      >
                        Sign Up
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>

                  <div className={classes.textWraper}>
                    <hr className={classes.hr}></hr>
                    <div className={classes.btnWraper}>Or Using</div>
                    <hr className={classes.hrRight}></hr>
                  </div>

                  <div className={classes.gLogo}>
                    <img src={glogo} className={classes.glogoCls} />
                  </div>

                  <div className={classes.signUpsec}>
                    Already have an account?
                    <span
                      className={classes.buttonSignIN}
                      onClick={signInHandler}
                    >
                      Login
                    </span>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SignUp;
