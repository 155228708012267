import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const IssueTypeAllocationTrend = ({ graphData }) => {
  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.PieChart);
    am4core.useTheme(am4themes_animated);
    chart.data = graphData;

    chart.innerRadius = am4core.percent(50);

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.labels.template.disabled = true;
    pieSeries.slices.template.tooltipText = "{additionalInfo}";

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    // Add a legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "right"; // Set legend position to the right
  }, [graphData]);
  return <div id="chartdiv" style={{ width: "100%", height: "400px" }} />;
};

export default IssueTypeAllocationTrend;
