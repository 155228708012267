import { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green, red } from "@material-ui/core/colors";
import { Alert, AlertTitle } from "@material-ui/lab";
import * as React from "react";
import axios from "../../utils/axiosHelper";
import { GlobalData } from "../../context/globalData";
import { pricePlan } from "../../utils/PricingPlan";

import logo from "../../assets/itechLogo.png";
import screensIcons from "../../assets/itechgenicLogin.png";
import emailIcons from "../../assets/email-logo.png";
import passwordIcons from "../../assets/password-logo.png";
import logo4 from "../../assets/Itegenic_logo.jpeg";
import { useHistory } from "react-router-dom";
import itegenic_logo4 from "../../assets/itegenic_logo4.png";
import Devboost from "../../assets/Devboost-logo.png";
import DevboostSvg from "../../assets/Devboost-logo.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { InputAdornment } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  head: {
    // marginBottom: "200px",
    // position: "relative",
    // width: "100%",
    height: "100%",
    top: "0px",
    margin: 0,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: "14% 20%",
  },
  // root: {
  //   // marginTop: "5px",

  // },
  inputWraper: {
    display: "flex",
    overflow: "hidden",
    marginTop: "-10px",
    marginLeft: "-40px",
    // borderBottom: "solid #eeeeee 0.5px",
    // width: "200px",
    // textAlign: "left",
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#082C49",
    marginBottom: "15px",
    marginTop: "15px",
  },
  input: {
    width: "350px",
    paddingLeft: "50px",
    height: "15px",
    margin: "22px 5px",
    font: "normal normal normal 18px/22px Lexend Deca",
  },
  btn: {
    display: "flex",
    // alignItems:"center",
    justifyContent: "center",
    margin: "20px 75px 20px 102px",
    width: "190px",
    padding: "25px 2px",
    height: "40px",
    borderRadius: "32px",
    background: "#5EBDCC",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#092C49",
    },
  },
  btnWraper: {
    width: "200px",
    marginTop: "20px",
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  alert: {
    // width: "50%",
    // position: "absolute",
    // display: "flex",
    // justifyContent: "center",
    marginTop: "20px",
  },

  btnnew: {
    display: "flex",
    marginTop: "20px",
    width: "410px",
  },
  notchedOutline: {
    // borderWidth: "1px",
    // borderRadius: "32px",
    background: "transparent",
    border: "none",
    margin: "5% 5%",
    width: "100%",
    borderRadius: "0px",
    borderBottom: "solid #eeeeee 0.5px",
  },

  termsLogin: {
    textAlign: "left",
  },
  signUpsec: {
    textAlign: "right",
    flex: "auto",
  },
  signUpFooter: {
    display: "flex",
    width: "410px",
  },

  fotgetPass: {
    cursor: "pointer",
    flex: "auto",
    textAlign: "right",
    marginRight: "25px",
    color: "#5EBDCC",
  },
  rememberPass: {
    flex: "auto",
    textAlign: "left",
    background: "transparent 0% 0% no-repeat padding-box",
    marginRight: "20px",
    marginLeft: "7px",
  },

  hr: {
    display: "flex",
    // justifyContent:"center",
    // alignItems:"center",
    marginTop: "20px",
    //  position:"relative",
    width: "210px",
    textAlign: "center",
  },

  cardLayout: {
    paddingLeft: "0px",
  },
  line: {
    textalign: "left",
    font: "normal normal normal 18px/22px Lexend Deca",
    letterspacing: "0px",
    color: "#3D3D3D",
    opacity: "1",
  },
  buttonSignUP: {
    cursor: "pointer",
    textDecoration: "underline",
    font: " normal normal 600 14px/22px Lexend Deca",
    letterspacing: "0px",
    color: " #5EBDCC",
    opacity: "1",
  },
  companyLogo: {
    // width: "124px",
    // height: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  usrImgmail: {
    position: "relative",
    top: "15px",
    left: "40px",
    width: "20px",
    height: "14px",
    marginTop: "4%",
    // marginLeft: "12%",
  },
  usrImgPassword: {
    position: "relative",
    top: "15px",
    left: "40px",
    width: "18px",
    height: "18px",
    marginTop: "3%",
    // marginLeft: "12%",
  },
}));

// ? do not delete
// let data = [];
// let users= localStorage.getItem("userList");
// let userlist= JSON.parse(users);

// userlist?.map((item) => {
//   const obj= {};
//   obj.email = item?.email;
//   obj.password = item?.password;
//   data?.push(obj);
// });

export default function Signin(props) {
  const history = useHistory();
  const classes = useStyles();
  const {
    setIsLogin,
    setRole,
    setCurrentRender,
    isLogin,
    uniqueuserlistByEmail,
    setUniqueuserlistByEmail,
  } = useContext(GlobalData);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [isSavedUser, setIsSavedUser] = React.useState(false);
  // const [uniqueuserlistByEmail, setUniqueuserlistByEmail] = React.useState(); // do not delete
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const validationSchema = yup.object({
    email: yup.string("Enter your email").required("Enter a valid email"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      axios
        .post("/api/auth/login", values)
        .then((result) => {
          if (result?.data?.success) {
            localStorage.setItem("token", result?.data?.token);
            localStorage.setItem("user", JSON.stringify(result?.data?.user));
            localStorage.setItem(
              "isSetupDone",
              JSON.stringify(result?.data?.user?.isSetupDone)
            );
            pricePlan();
            setRole(result.data.user.isAdministrator);
            setIsLogin(true);
            setLoading(false);
            setCurrentRender(0);
            result.data.user.isSetupDone
              ? history.push("/sprint")
              : history.push("/auth/onBoarding");
            // delete code
            // if (checked === true) {
            //   data.push({
            //     email: values?.email,
            //     password: values?.password,
            //   });
            //   localStorage.setItem("userList", JSON.stringify(data));
            // }

            // ? do not delete code
            // if (checked === true) {
            //   if (data?.length === 0) {
            //     formik.resetForm();
            //     data?.push({
            //       email: values?.email,
            //       password: values?.password,
            //     });
            //     localStorage.setItem("userList", JSON.stringify(data));
            //     // history.push("/forgetPassword");
            //   } else {
            //     let check = data?.findIndex((item) => item?.email === values?.email);
            //     if (check === -1) {
            //       data?.push({
            //         email: values?.email,
            //         password: values?.password,
            //       });
            //       localStorage.setItem("userList", JSON.stringify(data));
            //       // history.push("/forgetPassword");
            //       formik.resetForm();
            //     } else {
            //       console.log("User already exist.");
            //       setError(true);
            //     }
            //   }
            // }

            // delete this code last
            // else {
            //   setLoading(true);
            //   axios
            //     .post("/api/auth/login", values)
            //     .then((result) => {
            //       if (result.data.success) {
            //         localStorage.setItem("token", result.data.token);
            //         localStorage.setItem("user", JSON.stringify(result.data.user));
            //         localStorage.setItem(
            //           "isSetupDone",
            //           JSON.stringify(result.data.user.isSetupDone)
            //         );
            //         pricePlan();
            //         setRole(result.data.user.isAdministrator);
            //         setIsLogin(true);
            //         setLoading(false);
            //         setCurrentRender(0);
            //         result.data.user.isSetupDone
            //           ? history.push("/project")
            //           : history.push("/auth/onBoarding");
            //       }
            //     })
            //     .catch((err) => {
            //       setErrorMessage(err.response?.data?.error);
            //       setLoading(false);
            //       setError(true);
            //       setTimeout(function () {
            //         setError(false);
            //       }, 3000);
            //     });
            // }
          }
        })
        .catch((error) => {
          console.log(error, "error");
          setErrorMessage(error.response?.data?.error);
          setLoading(false);
          setError(true);
          setTimeout(function () {
            setError(false);
          }, 3000);
        });

      // Delete below code  last
      // if (checked === true) {
      //   if (data?.length === 0) {
      //     formik.resetForm();
      //     data?.push({
      //       email: values?.email,
      //       password: values?.password,
      //     });
      //     localStorage.setItem("userList", JSON.stringify(data));
      //     history.push("/forgetPassword");
      //   } else {
      //     let check = data?.findIndex((item) => item?.email === values?.email);
      //     if (check === -1) {
      //       data?.push({
      //         email: values?.email,
      //         password: values?.password,
      //       });
      //       localStorage.setItem("userList", JSON.stringify(data));
      //       history.push("/forgetPassword");
      //       formik.resetForm();
      //     } else {
      //       console.log("User already exist.");
      //       setError(true);
      //     }
      //   }
      // } else {
      //   setLoading(true);
      //   axios
      //     .post("/api/auth/login", values)
      //     .then((result) => {
      //       if (result.data.success) {
      //         localStorage.setItem("token", result.data.token);
      //         localStorage.setItem("user", JSON.stringify(result.data.user));
      //         localStorage.setItem(
      //           "isSetupDone",
      //           JSON.stringify(result.data.user.isSetupDone)
      //         );
      //         pricePlan();
      //         setRole(result.data.user.isAdministrator);
      //         setIsLogin(true);
      //         setLoading(false);
      //         setCurrentRender(0);
      //         result.data.user.isSetupDone
      //           ? history.push("/project")
      //           : history.push("/auth/onBoarding");
      //       }
      //     })
      //     .catch((err) => {
      //       setErrorMessage(err.response?.data?.error);
      //       setLoading(false);
      //       setError(true);
      //       setTimeout(function () {
      //         setError(false);
      //       }, 3000);
      //     });
      // }
    },
  });

  const signUpHandler = () => {
    // console.log("signUpHandler....",)
    history.push("/signUp");
  };

  const forgetPassword = useHistory();
  const forgetPasswordHandler = () => {
    history.push("/forgetPassword");
  };

  // ? do not delete code
  // useEffect(() => {
  //   if (userlist) {
  //     const key = 'email';
  //     const uniqueuserlistByEmail = [...new Map(userlist.map(item =>
  //       [item[key], item])).values()];
  //       setUniqueuserlistByEmail(uniqueuserlistByEmail);
  //     setIsSavedUser(true);
  //   }
  // }, []);

  // ? do not delete code
  // const loginBackHandler = () => {
  //   history.push("/login");
  //   setIsSavedUser(false)
  // };

  // ? do not delete code
  // const selectedUserLoginHandler = (item) => {
  //   let values = {
  //     email: item?.email,
  //     password: item?.password,
  //   }
  //   setLoading(true);
  //   axios
  //     .post("/api/auth/login", values)
  //     .then((result) => {
  //       if (result.data.success) {
  //         localStorage.setItem("token", result.data.token);
  //         localStorage.setItem("user", JSON.stringify(result.data.user));
  //         localStorage.setItem(
  //           "isSetupDone",
  //           JSON.stringify(result.data.user.isSetupDone)
  //         );
  //         pricePlan();
  //         setRole(result.data.user.isAdministrator);
  //         setIsLogin(true);
  //         setLoading(false);
  //         setCurrentRender(0);
  //         result.data.user.isSetupDone
  //           ? history.push("/project")
  //           : history.push("/auth/onBoarding");
  //       }
  //     })
  //     .catch((err) => {
  //       setErrorMessage(err.response?.data?.error);
  //       setLoading(false);
  //       setError(true);
  //       setTimeout(function () {
  //         setError(false);
  //       }, 3000);
  //     });
  // };

  const visibilityHandler = () => {
    setIsVisiblePassword(!isVisiblePassword);
  };

  return (
    <div
      className="row "
      style={{
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        position: "absolute",
        background: "white",
      }}
    >
      <div
        className="col-7 d-flex align-items-center"
        style={{
          width: "50%",
          background: "black",
          color: "#FFFF",
          height: "100%",
        }}
      >
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <img
              // src={itegenic_logo4}
              src={DevboostSvg}
              // src={Devboost}
              alt="itechgenic"
              style={{
                width: "429px",
                textAlign: "center",
              }}
            />
          </div>
          {/* 
          <h4
            className="col-12 d-flex justify-content-center"
            style={{
              color: "white",
              font: "normal normal normal 24px/22px Lexend Deca",
              letterspacing: "0px",
              color: " #FFFFFF",
              opacity: "1",
            }}
          >
            Connect with many more application
          </h4> */}
          <div
            className="col-12 d-flex justify-content-center mt-4"
            style={{
              color: "#FFF",
              fontSize: "18px",
              fontWeight: "800",
              letterspacing: "0px",
            }}
          >
            Enhance your cycle time and improve code quality.
          </div>
          <div style={{ width: "fit-content", margin: "10px auto" }}>
            <ul style={{ fontSize: 16 }}>
              <li> Faster speed to market</li>
              <li> On-time successful release</li>
              <li> Code quality improvement</li>
              <li> Reduction in Costs</li>
              <li> Better process governance</li>
              <li> Better utilization of people within the engineering team</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-5 d-flex">
        <div className={classes.head}>
          {/* <center> */}
          <form
            noValidate
            autoComplete="off"
            className={classes.root}
            onSubmit={formik.handleSubmit}
          >
            <div className="d-flex justify-content-center align-item-center">
              <img
                // src={logo}
                src={DevboostSvg}
                // src={Devboost}
                alt="itechgenic-logo"
                style={{
                  width: "70%",
                  height: "50%",
                  marginBottom: "10%",
                }}
              />
            </div>
            <h1 style={{ font: "normal normal 600 32px/22px Lexend Deca" }}>
              Login
            </h1>
            <h1
              style={{
                font: "normal normal normal 14px/22px Lexend Deca",
                marginBottom: "25px",
                marginTop: "15px",
              }}
            >
              {!isSavedUser
                ? " Please enter your details"
                : " Please select user"}
            </h1>
            {!isSavedUser ? (
              <>
                {" "}
                <div className={classes.inputWraper}>
                  {/* <img
                    src={emailIcons}
                    alt="email-logo"
                    style={{
                      width: "20px",
                      height: "14px",
                      marginTop: "8%",
                    }}
                  /> */}
                  <img src={emailIcons} className={classes.usrImgmail} />
                  <TextField
                    id="email"
                    name="email"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                    }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    placeholder="Email address"
                  />
                </div>
                <div className={classes.inputWraper}>
                  {/* <img
                    src={passwordIcons}
                    alt="password-logo"
                    style={{
                      width: "18px",
                      height: "18px",
                      marginTop: "8%",
                    }}
                  /> */}
                  <img src={passwordIcons} className={classes.usrImgPassword} />
                  <TextField
                    id="password"
                    name="password"
                    type={!isVisiblePassword ? "password" : "text"}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          {!isVisiblePassword ? (
                            <VisibilityOffIcon
                              style={{ cursor: "pointer", color: "#7E7E7E" }}
                              onClick={() => {
                                visibilityHandler();
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              style={{ cursor: "pointer", color: "#7E7E7E" }}
                              onClick={() => {
                                visibilityHandler();
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    placeholder="Password"
                  />
                </div>
                <div className={classes.btnnew}>
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                  />
                  <label className={classes.rememberPass}>
                    Remember password
                  </label>
                  <div
                    className={classes.fotgetPass}
                    onClick={forgetPasswordHandler}
                  >
                    Forgot Password?
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.btn}
                    disabled={loading}
                  >
                    Login
                  </Button>
                </div>
              </>
            ) : (
              <>
                <h1>Already have user</h1>
                {/* {uniqueuserlistByEmail &&
                  uniqueuserlistByEmail?.map((item) => (
                    <div
                      onClick={() => {
                        selectedUserLoginHandler(item);
                      }}
                    >
                      {item?.email}
                    </div>
                  ))} */}
                {/* <h4
                  onClick={() => {
                    loginBackHandler();
                  }}
                >
                  Login with other account
                </h4> */}
              </>
            )}

            {/* <hr  */}
            {/* <hr/><span ><hr className="width:50px"/>Or Using</span><hr/> */}
            <div className={classes.signUpFooter}>
              <div className={classes.termsLogin}>
                itechgenic
                <br />
                Terms & Privacy Policy
              </div>

              <div className={classes.signUpsec}>
                Don't have account?
                <br />
                <span className={classes.buttonSignUP} onClick={signUpHandler}>
                  Sign Up
                </span>
              </div>
            </div>
            {error && (
              <div className={classes.alert}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {errorMessage}
                </Alert>
              </div>
            )}
            {/* <div className={classes.btnWraper}>
                <Button
                variant="contained"
                className={classes.btn}
                type="submit"
                disabled={loading}
                >
                Login
                </Button>
                {loading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
                )}
            </div> */}
          </form>
          {/* </center> */}
        </div>
      </div>
    </div>
  );
}
