import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Popover,
  TextField,
} from "@material-ui/core";
import NormalSelect from "../select/normalSelect";
import RangeDatePicker from "../RangeDatePicker";
import EpicDropDown from "../epicDropDown";
import axios from "../../utils/axiosHelper";
import { config } from "../../Config/permission";
import CheckSelectId from "../select/CheckSelectId";

function Filter({
  setDate,
  date,
  issueType,
  setIssueType,
  tag,
  setTag,
  setCostAmt,
  costAmt,
  iteration,
  setIteration,
  selectedViewBy,
  setSelectedViewBy,
  anchorEl,
  handleClose,
  handleClick,
  ApplyHandler,
  epicDropDownHandler,
  filterTagOption,
  showTeamSelect,
  setSelectedTeam,
  selectedTeam,
}) {
  const optionOFDaySelect = [{ label: "Weekly", value: 14 }];

  return (
    <Box display={"flex"} gridGap={10} alignItems={"start"}>
      <RangeDatePicker setDate={setDate} date={date} isNextDate={false} />
      {/* <Box display={"flex"} alignItems={"center"} gridGap={10}>
                <label htmlFor="">View&nbsp;By:</label>
                <NormalSelect
                    option={[
                        { label: "Task", value: "Task" },
                        { label: "Epic", value: "Epic" },
                    ]}
                    value={issueType}
                    handleChange={(value) => {
                        setIssueType(value)
                        if (value === 'Task') {
                            epicDropDownHandler("", [])
                        }
                    }}
                />
            </Box> */}
      {epicDropDownHandler && (
        <EpicDropDown
          epicDropDownHandler={epicDropDownHandler}
          limitTags={1}
          width={400}
          style={{ width: 400, marginBottom: 0 }}
        />
      )}
      <Box>
        <NormalSelect
          option={optionOFDaySelect}
          // {[
          // { label: "Monthly", value: 30 },
          // { label: "Biweekly", value: 14 },
          // { label: "Weekly", value: 7 },
          // { label: "Daily", value: 1 },
          // ]}
          value={iteration}
          handleChange={(value) => {
            setIteration(value);
          }}
        />
      </Box>
      <Box display={"flex"} alignItems={"center"} gridGap={10}>
        <label htmlFor="">Tag:</label>
        <NormalSelect
          option={filterTagOption}
          // option={[
          //   { label: "Bug", value: "Bug" },
          //   { label: "Improvement", value: "Improvement" },
          //   { label: "New Feature", value: "New Feature" },
          //   { label: "Story", value: "Story" },
          // ]}
          value={tag}
          handleChange={(value) => {
            setTag(value);
          }}
        />
      </Box>
      <ButtonGroup color="primary" size="large">
        <Button style={{ minHeight: 53 }} disabled>
          View By
        </Button>
        ,
        <Button
          style={{ minHeight: 53 }}
          variant={selectedViewBy === "FTE" ? "contained" : "outlined"}
          onClick={() => setSelectedViewBy("FTE")}
        >
          FTE
        </Button>
        <Button
          style={{ minHeight: 53 }}
          variant={selectedViewBy === "Cost" ? "contained" : "outlined"}
          onClick={handleClick}
        >
          Cost $
        </Button>
        <Button
          style={{ minHeight: 53 }}
          variant={selectedViewBy === "Allocation" ? "contained" : "outlined"}
          onClick={() => setSelectedViewBy("Allocation")}
        >
          Allocation Hrs
        </Button>
      </ButtonGroup>
      {showTeamSelect && (
        <Box>
          <CheckSelectId
            names={showTeamSelect}
            setValue={(value) => {
              setSelectedTeam(value);
            }}
            value={selectedTeam}
            defaultValue={showTeamSelect[0].id}
          />
        </Box>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card>
          <CardContent>
            <div>
              <label>
                Please put your fully loaded engineers cost per{" "}
                {config.type === "Task" ? "Week" : "Month"}
              </label>
              <br />
              <Box display={"flex"} alignItems={"center"} gridGap={2}>
                $
                <TextField
                  value={costAmt}
                  onChange={(e) => {
                    setCostAmt(e.target.value);
                  }}
                  style={{
                    width: "400px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </Box>
              <br />
              <Button
                disabled={costAmt ? false : true}
                onClick={() => {
                  ApplyHandler();
                }}
                variant="outlined"
                color="primary"
              >
                {" "}
                Apply
              </Button>
            </div>
          </CardContent>
        </Card>
      </Popover>
    </Box>
  );
}

export default Filter;
