import { Card, CardContent, Grid, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { RiskColorWrapperWithIcon } from "../../../components/overview/riskWrapper";
import { style } from "../../Project/projectDashboard/style";
import { datePayload } from "../../../utils/moduleTabApi";
import axios from "../../../utils/axiosHelper";
import Trend from "../../Project/projectDashboard/trend";
import { getMetricRange } from "../../../utils/getMetricRange";
import HealthColorWrapper from "../../../components/overview/healthColorWrapper";
import handIcon from "../../../assets/twoHandsIcon.png";
import IssueLevel from "../../Project/projectDashboard/IssueLevel";

const useStyles = style;

const TeamDoraMetrics = ({ date, projectId, teamId }) => {
  const classes = useStyles();
  const [teamDoraMetrics, setTeamDoraMetrics] = useState();
  const [range, setRange] = useState();
  const [teamIssueType, setTeamIssueType] = useState();

  const teamDoras = () => {
    const dateUrl = datePayload(date);
    const url = `/api/v2/projects/doraMatric/${projectId}?${dateUrl}&teamId=${teamId}`;
    axios
      .get(url)
      .then((res) => {
        setTeamDoraMetrics(res?.data[0]);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(() => {
    teamDoras();
  }, [date]);

  const viewMoreHandler = (name) => {
    setTeamIssueType(name);
  };
  // get ranges
  useEffect(() => {
    axios
      .get(`/api/risks?ProjectId=${projectId}`)
      .then((result) => {
        setRange(result?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [projectId]);
  const DeploymentFrequencyRisk = useMemo(
    () =>
      getMetricRange(
        range,
        teamDoraMetrics?.deploymentFrequency,
        "Deployment Frequency"
      ),
    [teamDoraMetrics?.deploymentFrequency, range]
  );
  const ChangeFailureRateRisk = useMemo(
    () =>
      getMetricRange(
        range,
        teamDoraMetrics?.changeFailure,
        "Change Failure Rate"
      ),
    [teamDoraMetrics?.changeFailure, range]
  );
  const DeploymentRollbackRisk = useMemo(
    () =>
      getMetricRange(
        range,
        teamDoraMetrics?.deploymentRollback,
        "Deployment Rollback"
      ),
    [teamDoraMetrics?.deploymentRollback, range]
  );
  const MeanLeadTimeRisk = useMemo(
    () =>
      getMetricRange(
        range,
        teamDoraMetrics?.meanLeadTimeChange,
        "Mean Lead Time"
      ),
    [teamDoraMetrics?.meanLeadTimeChange, range]
  );
  console.log(
    DeploymentRollbackRisk,
    range?.filter((e) => e.DisplayName === "Deployment Rollback"),
    "range"
  );
  return (
    <div>
      <Grid container spacing={2} className="my-4">
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={handIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>
                    {teamDoraMetrics?.deploymentFrequency} Count
                  </h3>
                  <h6>
                    Deployment Frequency (Jenkins)
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() =>
                        viewMoreHandler("Team Deployment Frequency")
                      }
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>
                          Status: {DeploymentFrequencyRisk?.RiskCategory} Risk
                        </h6>
                        <h6>
                          Target:{DeploymentFrequencyRisk?.From?.$numberDecimal}
                          (Count) to{" "}
                          {DeploymentFrequencyRisk?.To?.$numberDecimal}(Count)
                        </h6>
                        <h6>Recommendations :</h6>
                        <ul>
                          <li>Strengthen the Ci/Cd Pipelines.</li>
                          <li>
                            Prioritize automation in testing and deployment.
                          </li>
                          <li>
                            Experiment with smaller, more frequency deployments
                            to gauge impact.
                          </li>
                        </ul>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={DeploymentFrequencyRisk?.RiskCategory}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={handIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>
                    {teamDoraMetrics?.changeFailure}%
                  </h3>
                  <h6>
                    Change failure rate (Jenkins )
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() =>
                        viewMoreHandler("Team Change failure rate")
                      }
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>
                          Status: {ChangeFailureRateRisk?.RiskCategory} Risk
                        </h6>
                        <h6>
                          Target:{ChangeFailureRateRisk?.From?.$numberDecimal}%
                          to {ChangeFailureRateRisk?.To?.$numberDecimal}%
                        </h6>{" "}
                        <h6>Recommendations :</h6>
                        <ul>
                          <li>
                            Analyse changes for patterns: Analyse recent failed
                            changes to pinpoint recurring issues. Identifying
                            patterns in past failures helps address root causes,
                            allowing the team to implement targeted improvements
                            and prevent similar issues from happening again .
                          </li>
                          <li>Foster a culture of collective accountability</li>
                          <li>Embrace automation</li>
                        </ul>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={ChangeFailureRateRisk?.RiskCategory}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={handIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>
                    {" "}
                    {teamDoraMetrics?.deploymentRollback}(Hours)
                  </h3>
                  <h6>
                    Deployment rollback(Jenkins)
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() =>
                        viewMoreHandler("Team Deployment rollback")
                      }
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>
                          Status: {DeploymentRollbackRisk?.RiskCategory} Risk
                        </h6>
                        <h6>
                          Target:{DeploymentRollbackRisk?.From?.$numberDecimal}
                          (Hours) to{" "}
                          {DeploymentRollbackRisk?.To?.$numberDecimal}(Hours)
                        </h6>
                        <h6>Recommendations :</h6>
                        <ul>
                          <li>
                            - Perform design reviews to make sure and identify
                            all dependencies.
                          </li>
                          <li>
                            Setup a deployment process to avoid outages like
                            implement blue-green deployments or gradual
                            rollouts.
                          </li>
                          <li>
                            For every rollback, do a RCA and fix the identified
                            issues.
                          </li>
                        </ul>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={DeploymentRollbackRisk?.RiskCategory}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={handIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>
                    {" "}
                    {teamDoraMetrics?.meanLeadTimeChange} (Hours)
                  </h3>
                  <h6>
                    Mean Lead Time for Change(Jenkins)
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() =>
                        viewMoreHandler("Mean Lead Time for Change")
                      }
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>Status: {MeanLeadTimeRisk?.RiskCategory} Risk</h6>
                        <h6>
                          Target:{MeanLeadTimeRisk?.From?.$numberDecimal}(Hours)
                          to {MeanLeadTimeRisk?.To?.$numberDecimal}(Hours)
                        </h6>
                        <h6>Recommendations :</h6>
                        <ul>
                          <li>
                            Reduce Lead Time for Changes . It will reduce
                            overall Lead Time and increase software delivery
                            velocity. Empirical evidence shows that it is not
                            uncommon to reduce Lead Time by 5-10% through
                            tracking and effective management of Lead Time for
                            Changes
                          </li>
                        </ul>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={MeanLeadTimeRisk?.RiskCategory}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {[
        "Team Deployment Frequency",
        "Team Change failure rate",
        "Team Deployment rollback",
        "Mean Lead Time for Change",
      ].includes(teamIssueType) && (
        <Card style={{ marginTop: 20 }}>
          <Trend
            issueType={teamIssueType}
            projectId={projectId}
            teamId={teamId}
          />
        </Card>
      )}
      <>
        {[
          "Team Deployment Frequency",
          "Team Change failure rate",
          "Team Deployment rollback",
          "Mean Lead Time for Change",
        ].includes(teamIssueType) && (
          <Card style={{ marginTop: 20 }}>
            <IssueLevel
              issueType={teamIssueType}
              assigneeList={[]}
              statusList={[]}
              subStatus={[]}
              projectId={projectId}
              autoScroll={[
                "Team Deployment Frequency",
                "Team Change failure rate",
                "Team Deployment rollback",
                "Mean Lead Time for Change",
              ].includes(teamIssueType)}
              isDate={
                [
                  "Team Deployment Frequency",
                  "Team Change failure rate",
                  "Team Deployment rollback",
                  "Mean Lead Time for Change",
                ].includes(teamIssueType)
                  ? true
                  : false
              }
            />
          </Card>
        )}
      </>
    </div>
  );
};

export default TeamDoraMetrics;
